import React, { useState } from 'react';
import ReportSearchForm from '../searchform/ReportSearchForm';
import ReportList from './ReportList';
import EstimateList from './EstimateList';
import EstimateSearchForm from '../searchform/EstimateSearchForm';
import InfraSearchForm from '../searchform/InfraSearchForm';
import InfraList from './InfraList';

import Header from '../header/Header';
import './List.css';

const List: React.FC = () => {
    const [filterReportParams, setFilterReportParams] = useState<{
        reportDateFrom: string;
        reportDateTo: string;
        reportPlace: string;
        reportCategory: string;
        reportAnswer: string;
        reportStatus: string;
    } | null>(null);

    const [filterEstimateParams, setFilterEstimateParams] = useState<{
        estimateCreatedFrom: string;
        estimateCreatedTo: string;
        estimateInfraName: string;
        estimateAmountFrom: string;
        estimateAmountTo: string;
        estimateCreatedBy: string;
    } | null>(null);

    const [filterInfraParams, setFilterInfraParams] = useState<{
        infraCategory: string;
        infraName: string;
        infraSoundness: string;
        infraStatus: string;
        infraUpdateFrom: string;
        infraUpdateTo: string;
    } | null>(null);

    const handleReportSearch = (params: typeof filterReportParams) => {
        setFilterReportParams(params);
    };

    const handleReportClear = () => {
        setFilterReportParams(null);
    };

    const handleEstimateSearch = (params: typeof filterEstimateParams) => {
        setFilterEstimateParams(params);
    };

    const handleEstimateClear = () => {
        setFilterEstimateParams(null);
    };

    const handleInfraSearch = (params: typeof filterInfraParams) => {
        setFilterInfraParams(params);
    };

    const handleInfraClear = () => {
        setFilterInfraParams(null);
    };

    return (
        <div className='page'>
            <Header />
            <div className='subheader'>
                <h4>各リスト一覧</h4>
            </div>
            <div className='list'>
                <h4>報告リスト</h4>
                <ReportSearchForm onSearch={handleReportSearch} onClear={handleReportClear}/>
                <ReportList filterReportParams={filterReportParams} />
                <h4>見積リスト</h4>
                <EstimateSearchForm onSearch={handleEstimateSearch} onClear={handleEstimateClear}/>
                <EstimateList filterEstimateParams={filterEstimateParams} />
                <h4>インフラリスト</h4>
                <InfraSearchForm onSearch={handleInfraSearch} onClear={handleInfraClear}/>
                <InfraList filterInfraParams={filterInfraParams} />
            </div>
        </div>
    );
};

export default List;