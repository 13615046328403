import React, { useState, useEffect } from 'react';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import L from 'leaflet';
import { useNavigate } from 'react-router-dom';

import Header from '../header/Header';
import './LeafletMap.css';

import infraIconUrl from '../icon/tool.svg';
import reportIconUrl from '../icon/flag.svg';
import currentIconUrl from '../icon/mp_colored_red2.png';

interface Infra {
  id: string;
  category: string;
  name: string;
  address: string;
  soundness: string;
  status: string;
  update: string;
}

interface Report {
  id: string;
  date: string;
  category: string;
  answer: string;
  status: string;
  latitude: string;
  longitude: string;
}

const infraIcon = new L.Icon({
  iconUrl: infraIconUrl,
  iconSize: [25, 25],
  iconAnchor: [12, 12],
});

const reportIcon = new L.Icon({
  iconUrl: reportIconUrl,
  iconSize: [25, 25],
  iconAnchor: [12, 12],
});

const Map: React.FC = () => {
  const [currentPosition, setCurrentPosition] = useState<[number, number]>([35.710179001728534, 139.8107304222906]);
  const [markers, setMarkers] = useState<JSX.Element[]>([]);
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem('authToken');
    if (!token) {
      console.error('Authentication token is not available.');
      alert('Please log in to access the data.');
      return;
    }
    const headers = {
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json'
    };
  
    Promise.all([
      fetch('https://twondstar-backend.onrender.com/read/infra', { method: 'GET', headers }),
      fetch('https://twondstar-backend.onrender.com/read/report', { method: 'GET', headers })
    ]).then(async ([infraResponse, reportResponse]) => {
      const infraData = await infraResponse.json();
      const reportData = await reportResponse.json();
  
      const infraMarkers = await Promise.all(infraData.map(async (infra: { address: string | number | boolean; id: React.Key | null | undefined; name: string | number | boolean | React.ReactElement<any, string | React.JSXElementConstructor<any>> | Iterable<React.ReactNode> | React.ReactPortal | null | undefined; }) => {
        if (typeof infra.address !== 'string') {
          console.error('Address is not a string:', infra.address);
          return null;
        }
        const cachedPosition = sessionStorage.getItem(infra.address);
        let position;
        if (cachedPosition) {
          position = JSON.parse(cachedPosition);
        } else {
          const geoResponse = await fetch(`https://nominatim.openstreetmap.org/search?format=json&q=${encodeURIComponent(infra.address)}`);
          const geoData = await geoResponse.json();
          if (geoData && geoData.length > 0) {
            position = [parseFloat(geoData[0].lat), parseFloat(geoData[0].lon)];
            sessionStorage.setItem(infra.address, JSON.stringify(position));
          } else {
            console.error('Geocoding failed for address:', infra.address);
            return null;
          }
        }
        return (
          <Marker key={infra.id} position={position} icon={infraIcon}>
            <Popup>
              <div onClick={() => navigate(`/detail/infra/${infra.id}`)} style={{ cursor: 'pointer' }}>
                <p className='link-detail'> {infra.name} </p>
              </div>
            </Popup>
          </Marker>
        );
      }));
  
      const reportMarkers = reportData.map((report: { id: any; latitude: number; longitude: number; answer: string | number | boolean | React.ReactElement<any, string | React.JSXElementConstructor<any>> | Iterable<React.ReactNode> | React.ReactPortal | null | undefined; category: string | number | boolean | React.ReactElement<any, string | React.JSXElementConstructor<any>> | Iterable<React.ReactNode> | React.ReactPortal | null | undefined; }) => {
        return (
          <Marker key={`report-${report.id}`} position={[report.latitude, report.longitude]} icon={reportIcon}>
            <Popup>
              <div onClick={() => navigate(`/detail/report/${report.id}`)} style={{ cursor: 'pointer' }}>
                <p className='link-detail'> {report.answer} ({report.category}) </p>
              </div>
            </Popup>
          </Marker>
        );
      });

      setMarkers([...infraMarkers.filter(marker => marker !== null), ...reportMarkers]);
    }).catch(error => {
      console.error('Error processing data:', error);
    });
  }, [navigate]);  

  useEffect(() => {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        setCurrentPosition([position.coords.latitude, position.coords.longitude]);
      },
      () => {
        console.error("Unable to retrieve your location.");
      }
    );
  }, []);

  const currentPositionIcon = new L.Icon({
    iconUrl: currentIconUrl,
    iconSize: [50, 50],
    iconAnchor: [25, 25],
  });

  return (
    <div className='map'>
      <Header />
      <MapContainer center={currentPosition} zoom={15} style={{ height: '100vh' }} key={currentPosition.toString()} minZoom={10}>
        <TileLayer url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png' />
        <Marker position={currentPosition} icon={currentPositionIcon} />
        {markers}
      </MapContainer>
    </div>
  );
};

export default Map;