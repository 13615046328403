import React from 'react';
import { MdDashboard, MdListAlt, MdMap, MdCalendarMonth, MdOutlineSettings, MdAccountBox, MdMenuBook, } from "react-icons/md";
import { Link } from 'react-router-dom';
import { useUser }  from './UserContext';

import logo from './icon/silhouette_white.png';

import './Sidebar.css';

const Sidebar: React.FC = () => {
  const { user, logout } = useUser();
  
  const handleLogout = () => {
    logout();
  };

  return (
    <div className='sidebar'>
      <div className='head'>
        <img src={logo} className="logo" alt="logo" />
        <Link to='/dashboard'><p className='icon-text-container'><MdDashboard />ダッシュボード</p></Link>
        <Link to='/list'><p className='icon-text-container'><MdListAlt />リスト</p></Link>
        <Link to='/map'><p className='icon-text-container'><MdMap />マップ</p></Link>
        <Link to='/schedule'><p className='icon-text-container'><MdCalendarMonth />カレンダー</p></Link>
      </div>
      <div className='foot'>
        <Link to='/setting'><p className='icon-text-container'><MdOutlineSettings />設定</p></Link>
        <Link to='/user'><p className='icon-text-container'><MdAccountBox />ユーザー情報</p></Link>
        <p className='icon-text-container'><MdMenuBook />マニュアル</p>
        <button className='username' onClick={handleLogout}>{user ? user.email : 'ゲスト'}</button>
      </div>
    </div>
  );
};

export default Sidebar;