import React, { ReactElement } from 'react';
import Login from  './login/Login';
import Signup from './signup/Signup';
import Dashboard from './dashboard/Dashboard';
import List from './list/List';
import Map from './leafletmap/LeafletMap';
import Schedule from './schedule/Schedule';
import DetailInfra from './detail/DetailInfra';
import DetailReport from './detail/DetailReport';
import DetailEstimate from './detail/DetailEstimate';
import Plan from './plan/Plan';

import 'leaflet/dist/leaflet.css';
import './MainContent.css';

interface MainContentProps {
  selectedComponent?: string;
}

const MainContent: React.FC<MainContentProps> = ({ selectedComponent }): ReactElement => {
  switch (selectedComponent) {
    case 'dashboard':
      return <Dashboard />;
    case 'signup':
      return <Signup />;
    case 'list':
      return <List />;
    case 'map':
      return <Map />;
    case 'schedule':
      return <Schedule />;
    case 'detail/infra':
      return <DetailInfra />;
    case 'detail/report':
      return <DetailReport />;
    case 'detail/estimate':
      return <DetailEstimate />;
    case 'plan':
      return <Plan />
    default:
      return <Login />;
  }
};

export default MainContent;
