import React, { useState, useEffect, useCallback } from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import jaLocale from '@fullcalendar/core/locales/ja';
import { useNavigate } from 'react-router-dom';

interface Report {
  id: string;
  create_at: string;
  category: string;
  answer: string;
  status: string;
  latitude: string;
  longitude: string;
}

interface Event {
  id: string;
  title: string;
  start: string;
}

interface CalenderProps {
  filterReportParams: {
    reportDateFrom: string;
    reportDateTo: string;
    reportPlace: string;
    reportCategory: string;
    reportAnswer: string;
    reportStatus: string;
  } | null;
}

const Calender: React.FC<CalenderProps> = ({ filterReportParams }) => {
  const [events, setEvents] = useState<Event[]>([]);
  const navigate = useNavigate();

  const fetchReportData = useCallback(async () => {
    try {
      const token = localStorage.getItem('authToken');
      const headers = {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      };

      const response = await fetch('https://twondstar-backend.onrender.com/read/report', {
        method: 'GET',
        headers: headers
      });

      if (!response.ok) throw new Error('Network response was not ok');
      let jsonData: Report[] = await response.json();

      if (filterReportParams) {
        jsonData = jsonData.filter(report => {
          const reportDate = new Date(report.create_at);
          const fromDate = filterReportParams.reportDateFrom ? new Date(filterReportParams.reportDateFrom) : null;
          const toDate = filterReportParams.reportDateTo ? new Date(filterReportParams.reportDateTo) : null;
          return (
            (!filterReportParams.reportCategory || report.category === filterReportParams.reportCategory) &&
            (!filterReportParams.reportAnswer || report.answer === filterReportParams.reportAnswer) &&
            (!filterReportParams.reportStatus || report.status === filterReportParams.reportStatus) &&
            (!fromDate || reportDate >= fromDate) &&
            (!toDate || reportDate <= toDate)
          );
        });
      }

      const events = jsonData.map((report: Report) => ({
        id: report.id,
        title: `${report.answer} (${report.category})`,
        start: report.create_at,
      }));
      setEvents(events);
    } catch (error) {
      console.error('Error fetching report data:', error);
    }
  }, [filterReportParams]);

  useEffect(() => {
    fetchReportData();
  }, [fetchReportData]);

  const handleEventClick = (clickInfo: { event: { id: any; }; }) => {
    navigate(`/detail/report/${clickInfo.event.id}`);
  };

  return (
    <FullCalendar
      plugins={[dayGridPlugin, timeGridPlugin, listPlugin]}
      initialView='dayGridMonth'
      locales={[jaLocale]}
      locale='ja'
      headerToolbar={{
        left: 'prev,next today',
        center: 'title',
        right: 'dayGridMonth,timeGridWeek,list',
      }}
      events={events}
      eventClick={handleEventClick}
      height='calc(100vh - 144px)'
    />
  );
}

export default Calender;