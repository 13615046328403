import React, { useState } from 'react';
import { Accordion, AccordionSummary, AccordionDetails, TextField, FormControl, InputLabel, Select, MenuItem, Button, Grid } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';

import './SearchForm.css';

interface InfraFilterParams {
  infraCategory: string;
  infraName: string;
  infraSoundness: string;
  infraStatus: string;
  infraUpdateFrom: string;
  infraUpdateTo: string;
};

interface InfraSearchFormProps {
  onSearch: (filterParams: InfraFilterParams) => void;
  onClear: () => void;
};

const InfraSearchForm = ({ onSearch, onClear }: InfraSearchFormProps) => {
  const [infraCategory, setInfraCategory] = useState('');
  const [infraName, setInfraName] = useState('');
  const [infraSoundness, setInfraSoundness] = useState('');
  const [infraStatus, setInfraStatus] = useState('');
  const [infraUpdateFrom, setInfraUpdateFrom] = useState('');
  const [infraUpdateTo, setInfraUpdateTo] = useState('');

  const handleSearch = () => {
    onSearch({ infraCategory, infraName, infraSoundness, infraStatus, infraUpdateFrom, infraUpdateTo });
  };

  const handleClear = () => {
    setInfraCategory('');
    setInfraName('');
    setInfraSoundness('');
    setInfraStatus('');
    setInfraUpdateFrom('');
    setInfraUpdateTo('');
    onClear();
  };

  return (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMoreIcon />} style={{ height: '60px', alignItems: 'center' }}>
        <h4>詳細検索</h4>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={4}>
            <FormControl fullWidth>
              <InputLabel id='category-label'>カテゴリー</InputLabel>
              <Select
                labelId='category-label'
                id='category-select'
                value={infraCategory}
                label='カテゴリー'
                onChange={(e) => setInfraCategory(e.target.value)}
              >
                <MenuItem value=''><em>選択してください</em></MenuItem>
                <MenuItem value='道路'>道路</MenuItem>
                <MenuItem value='橋梁'>橋梁</MenuItem>
                <MenuItem value='トンネル'>トンネル</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            <FormControl fullWidth>
              <TextField
                label='対象インフラ名'
                type='text'
                value={infraName}
                onChange={(e) => setInfraName(e.target.value)}
                InputLabelProps={{ shrink: true }}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            <FormControl fullWidth>
              <InputLabel id='soundness-label'>健全度</InputLabel>
              <Select
                labelId='soundness-label'
                id='soundness-select'
                value={infraSoundness}
                label='健全度'
                onChange={(e) => setInfraSoundness(e.target.value)}
              >
                <MenuItem value=''><em>選択してください</em></MenuItem>
                <MenuItem value='Ⅰ'>Ⅰ</MenuItem>
                <MenuItem value='Ⅱ'>Ⅱ</MenuItem>
                <MenuItem value='Ⅲ'>Ⅲ</MenuItem>
                <MenuItem value='Ⅳ'>Ⅳ</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            <FormControl fullWidth>
              <InputLabel id='status-label'>ステータス</InputLabel>
              <Select
                labelId='status-label'
                id='status-select'
                value={infraStatus}
                label='ステータス'
                onChange={(e) => setInfraStatus(e.target.value)}
              >
                <MenuItem value=''><em>選択してください</em></MenuItem>
                <MenuItem value='通行可能'>通行可能</MenuItem>
                <MenuItem value='通行止め'>通行止め</MenuItem>
                <MenuItem value='工事中'>工事中</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            <FormControl fullWidth>
              <TextField
                label='更新日(から)'
                type='date'
                value={infraUpdateFrom}
                onChange={(e) => setInfraUpdateFrom(e.target.value)}
                InputLabelProps={{ shrink: true }}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            <FormControl fullWidth>
              <TextField
                label='更新日(まで)'
                type='date'
                value={infraUpdateTo}
                onChange={(e) => setInfraUpdateTo(e.target.value)}
                InputLabelProps={{ shrink: true }}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} style={{display: 'flex', justifyContent: 'center'}}>
            <div className='modal-buttons'>
              <Button variant='outlined' color='primary' startIcon={<ClearIcon />} onClick={handleClear}>クリア</Button>
              <Button variant='contained' color='primary' startIcon={<SearchIcon />} onClick={handleSearch}>検索</Button>
            </div>
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};

export default InfraSearchForm;