import React, { useState, useEffect, useCallback } from 'react';
import {
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
  Paper, TablePagination, TableSortLabel, Button, CircularProgress
} from '@mui/material';
import { Link } from 'react-router-dom';

interface Infra {
  id: string;
  category: string;
  name: string;
  address: string;
  soundness: string;
  status: string;
  update: string;
}

interface ListProps {
  filterInfraParams: {
    infraCategory: string;
    infraName: string;
    infraSoundness: string;
    infraStatus: string;
    infraUpdateFrom: string;
    infraUpdateTo: string;
  } | null;
}

interface Header {
  label: string;
  value: keyof Infra | 'actions';
}

const InfraList: React.FC<ListProps> = ({ filterInfraParams }) => {
  const [data, setData] = useState<Infra[]>([]);
  const [rowsPerPage, setRowsPerPage] = useState<number>(5);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [orderBy, setOrderBy] = useState<string>('');
  const [order, setOrder] = useState<'asc' | 'desc'>('asc');
  const [loading, setLoading] = useState<boolean>(false);

  const fetchInfraData = useCallback(async () => {
    setLoading(true);
    const token = localStorage.getItem('authToken');
    const headers = {
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json'
    };
  
    try {
      const response = await fetch('https://twondstar-backend.onrender.com/read/infra', {
        method: 'GET',
        headers: headers
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const jsonData = await response.json();
      const filteredData = jsonData.filter((infra: Infra) => {
        const updateDate = new Date(infra.update);
        const fromDate = filterInfraParams?.infraUpdateFrom ? new Date(filterInfraParams.infraUpdateFrom) : null;
        const toDate = filterInfraParams?.infraUpdateTo ? new Date(filterInfraParams.infraUpdateTo) : null;
      
        const nameMatch = !filterInfraParams?.infraName || infra.name.toLowerCase().includes(filterInfraParams.infraName.toLowerCase());
        const soundnessMatch = !filterInfraParams?.infraSoundness || infra.soundness.toLowerCase().includes(filterInfraParams.infraSoundness.toLowerCase());
        const categoryMatch = !filterInfraParams?.infraCategory || infra.category === filterInfraParams.infraCategory;
        const statusMatch = !filterInfraParams?.infraStatus || infra.status === filterInfraParams.infraStatus;
      
        return (
          (!fromDate || updateDate >= fromDate) &&
          (!toDate || updateDate <= toDate) &&
          nameMatch &&
          soundnessMatch &&
          categoryMatch &&
          statusMatch
        );
      });
      
      
      setData(filteredData);
    } catch (error) {
      console.error('Error fetching infrastructure data:', error);
    }
    setLoading(false);
  }, [filterInfraParams]); // filterInfraParams を依存配列に追加  

  useEffect(() => {
    fetchInfraData();
  }, [fetchInfraData]); // fetchInfraData を useEffect の依存配列に追加

  // ページングコントロールのページ変更イベント
  const handleChangePage = (event: unknown, newPage: number) => {
    setCurrentPage(newPage);
  };

  // 表示行数変更イベント
  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setCurrentPage(0);
  };

  // ソート処理
  const handleSort = (property: string) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  // データフィルタリングとソート処理
  const sortedRows = orderBy ? [...data].sort((a, b) => {
    const aValue = a[orderBy as keyof Infra];
    const bValue = b[orderBy as keyof Infra];

    if (aValue === undefined || bValue === undefined) return 0; // どちらかが未定義の場合は等しいとみなす

    if (typeof aValue === 'number' && typeof bValue === 'number') {
      // 数値の比較
      return order === 'asc' ? aValue - bValue : bValue - aValue;
    } else {
      // 文字列として比較
      return order === 'asc' ? aValue.toString().localeCompare(bValue.toString()) : bValue.toString().localeCompare(aValue.toString());
    }
  }) : [...data];

  // 現在のページデータを計算
  const indexOfLastRow = (currentPage + 1) * rowsPerPage;
  const indexOfFirstRow = indexOfLastRow - rowsPerPage;
  const currentRows = sortedRows.slice(indexOfFirstRow, indexOfLastRow);

// ヘッダーの定義
const headers: Header[] = [
  { label: 'インフラNo.', value: 'id' },
  { label: 'カテゴリー', value: 'category' },
  { label: 'インフラ名', value: 'name' },
  { label: '所在地', value: 'address' },
  { label: '健全度', value: 'soundness' },
  { label: 'ステータス', value: 'status' },
  { label: '最終更新日', value: 'update' },
  { label: '', value: 'actions' }
];

  return (
    <div className='infralist'>
      <TableContainer component={Paper} style={{ overflow: 'auto' }}>
        <Table>
          <TableHead>
            <TableRow>
              {headers.map(header => (
                <TableCell key={header.value}>
                  <TableSortLabel
                    active={orderBy === header.value}
                    direction={orderBy === header.value ? order : 'asc'}
                    onClick={() => handleSort(header.value)}
                  >
                    {header.label}
                  </TableSortLabel>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              <TableRow>
                <TableCell colSpan={headers.length} style={{ textAlign: 'center' }}>
                  <CircularProgress />
                </TableCell>
              </TableRow>
            ) : (
              currentRows.map((row: Infra) => (
                <TableRow key={row.id}>
                  <TableCell>{row.id}</TableCell>
                  <TableCell>{row.category}</TableCell>
                  <TableCell>{row.name}</TableCell>
                  <TableCell>{row.address}</TableCell>
                  <TableCell>{row.soundness}</TableCell>
                  <TableCell>{row.status}</TableCell>
                  <TableCell>{new Date(row.update).toLocaleDateString()}</TableCell>
                  <TableCell>
                    <Link to={`/detail/infra/${row.id}`}><Button variant='contained' size='small' color='primary'>詳細</Button></Link>
                  </TableCell>
                </TableRow>
              ))
            )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component='div'
          count={sortedRows.length}
          rowsPerPage={rowsPerPage}
          page={currentPage}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          labelRowsPerPage='表示件数'
        />
      </div>
  );
};

export default InfraList;