import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Button } from '@mui/material';
import { useUser } from '../UserContext';

import './Login.css'

import logo from '../icon/silhouette_white.png';

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const { setUser } = useUser();
  const navigate = useNavigate();

  const handleLogin = async (e: { preventDefault: () => void; }) => {
    e.preventDefault();
    setLoading(true);
    const response = await fetch('https://twondstar-backend.onrender.com/login', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email, password }),
    });
  
    const data = await response.json();
  
    if (response.ok) {
      // ローカルストレージにトークンとユーザー情報を保存
      localStorage.setItem('authToken', data.token); // トークンを保存
      localStorage.setItem('userInfo', JSON.stringify({ email: data.user.email, project_id: data.user.project_id }));
      setUser({ email: data.user.email, project_id: data.user.project_id });
      navigate('/dashboard'); // ユーザーダッシュボードへリダイレクト
    } else {
      alert(`Error: ${data.message}`);
    }
    setLoading(false);
  };

  return (
    <div className='login'>
      <div className='login-header'>
        <img src={logo} alt='logo' className='logo-header'/>
      </div>
      <form onSubmit={handleLogin}>
        <div className='link'>
          <Link to='/signup'><p className='link'>アカウント作成</p></Link>
        </div>
        <div className='input-field'>
        <p>メールアドレス</p>
        <input type='email' placeholder='Email' value={email} onChange={(e) => setEmail(e.target.value)} className='login-input'/>
        </div>
        <div className='input-field'>
        <p>パスワード</p>
        <input type='password' placeholder='Password' value={password} onChange={(e) => setPassword(e.target.value)} className='login-input'/>
        </div>
        <div className='submit-margin'></div>
        <Button type='submit' disabled={loading} variant='contained' size='small' color='primary'>ログイン</Button>
      </form>
      <div className='footer'>

      </div>
    </div>
  );
};

export default Login;