import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Button } from '@mui/material';

import Header from '../header/Header';
import './Detail.css';

interface InfraData {
  id: string;
  category: string;
  name: string;
  address: string;
  soundness: string;
  status: string;
  update: string;
  img: string;
}

const DetailInfra: React.FC = () => {
  const { id } = useParams<{ id: string }>(); // URLからIDを取得
  const [infraData, setInfraData] = useState<InfraData | null>(null); // データを保持するstate
  const [reportCount, setReportCount] = useState<number | null>(null); // 通報回数を保持するstate
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem('authToken');
    const headers = {
      'Authorization': `Bearer ${token}`, // 認証トークンをリクエストヘッダーに設定
      'Content-Type': 'application/json'
    };
    // サーバーからデータを取得する関数を定義
    const fetchData = async () => {
      try {
        const response = await fetch(`https://twondstar-backend.onrender.com/read/infra/${id}`, {
          method: 'GET',
          headers: headers
        }); // URLにIDを含めてAPIにリクエスト
        const data = await response.json();
        setInfraData(data); // 取得したデータをstateにセット
      } catch (error) {
        console.error('Error fetching data: ', error);
      }
    };

    // 累積通報回数を取得する関数
    const fetchReportCount = async () => {
      try {
        const response = await fetch(`https://twondstar-backend.onrender.com/read/report_count/${id}`);
        const data = await response.json();
        setReportCount(data.reportCount); // 取得した通報回数をstateにセット
      } catch (error) {
        console.error('Error fetching report count:', error);
      }
    };

    fetchData();
    fetchReportCount();
  }, [id]); // idが変更された時のみ実行

  const goBack = () => {
    navigate(-1);
  };

  return (
    <div className='details'>
      <Header />
      <div className='subheader'>
        <h4>インフラ詳細</h4>
      </div>
      <div className='detail'>
        {infraData && (
          <img src={infraData.img} alt="Infrastructure Preview" className='infra-preview' />
        )}
        {infraData && (
          <div className='contents'>
            <div className='content'>
              <p className='title'>インフラNo.</p>
              <p className='No'>{infraData.id}</p>
              <div className='under-line'></div>
              <p className='title'>インフラ名</p>
              <p>{infraData.name}</p>
              <div className='under-line'></div>
              <p className='title'>ステータス</p>
              <p>{infraData.status}</p>
              <div className='under-line'></div>
              <p className='title'>更新日</p>
              <p className='No'>{infraData.update}</p>
              <div className='under-line'></div>
            </div>
            <div className='content'>
              <p className='title'>カテゴリー</p>
              <p>{infraData.category}</p>
              <div className='under-line'></div>
              <p className='title'>所在地</p>
              <p>{infraData.address}</p>
              <div className='under-line'></div>
              <p className='title'>健全度</p>
              <p className='No'>{infraData.soundness}</p>
              <div className='under-line'></div>
              <p className='title'>累積通報回数</p>
              <p className='No'>{reportCount}</p>
              <div className='under-line'></div>
            </div>
          </div>
        )}
        <div className='margin-top'>
          <Button variant='outlined' size='small' color='primary' onClick={goBack}>戻る</Button>
        </div>
      </div>
    </div>
  );
};

export default DetailInfra;