import React, { useState } from 'react';
import { Accordion, AccordionSummary, AccordionDetails, TextField, FormControl, InputLabel, Select, MenuItem, Button, Grid } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';

import './SearchForm.css';

interface FilterParams {
  reportDateFrom: string;
  reportDateTo: string;
  reportPlace: string;
  reportCategory: string;
  reportAnswer: string;
  reportStatus: string;
};

interface ReportSearchFormProps {
  onSearch: (filterParams: FilterParams) => void;
  onClear: () => void;
};

const ReportSearchForm = ({ onSearch, onClear }: ReportSearchFormProps) => {
  const [reportDateFrom, setReportDateFrom] = useState('');
  const [reportDateTo, setReportDateTo] = useState('');
  const [reportPlace, setReportPlace] = useState('');
  const [reportCategory, setCategory] = useState('');
  const [reportAnswer, setAnswer] = useState('');
  const [reportStatus, setStatus] = useState('');

  const handleSearch = () => {
    onSearch({
      reportDateFrom: reportDateFrom.trim(),
      reportDateTo: reportDateTo.trim(),
      reportPlace: reportPlace.trim(),
      reportCategory: reportCategory.toLowerCase().trim(),
      reportAnswer: reportAnswer.toLowerCase().trim(),
      reportStatus: reportStatus.toLowerCase().trim()
    });
  };

  const handleClear = () => {
    setReportDateFrom('');
    setReportDateTo('');
    setReportPlace('');
    setCategory('');
    setAnswer('');
    setStatus('');
    onClear();
  };

  return (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMoreIcon />} style={{ height: '60px', alignItems: 'center' }}>
        <h4>詳細検索</h4>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={4}>
            <FormControl fullWidth>
              <TextField
                label='報告日(から)'
                type='date'
                value={reportDateFrom}
                onChange={(e) => setReportDateFrom(e.target.value)}
                InputLabelProps={{ shrink: true }}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <FormControl fullWidth>
              <TextField
                label='報告日(まで)'
                type='date'
                value={reportDateTo}
                onChange={(e) => setReportDateTo(e.target.value)}
                InputLabelProps={{ shrink: true }}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <FormControl fullWidth>
              <TextField
                label='報告場所'
                type='text'
                value={reportPlace}
                onChange={(e) => setReportPlace(e.target.value)}
                InputLabelProps={{ shrink: true }}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <FormControl fullWidth>
              <InputLabel className='category-label'>カテゴリー</InputLabel>
              <Select
                labelId='category-label'
                id='category-select'
                value={reportCategory}
                label='カテゴリー'
                onChange={(e) => setCategory(e.target.value)}
              >
                <MenuItem value=''><em>選択してください</em></MenuItem>
                <MenuItem value='道路'>道路</MenuItem>
                <MenuItem value='草刈'>草刈</MenuItem>
                <MenuItem value='公園'>公園</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <FormControl fullWidth>
              <InputLabel className='answer-label'>自動判定</InputLabel>
              <Select
                labelId='answer-label'
                id='answer-select'
                value={reportAnswer}
                label='自動判定'
                onChange={(e) => setAnswer(e.target.value)}
              >
                <MenuItem value=''><em>選択してください</em></MenuItem>
                <MenuItem value='要対策'>要対策</MenuItem>
                <MenuItem value='経過観察'>経過観察</MenuItem>
                <MenuItem value='対策不要'>対策不要</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <FormControl fullWidth>
              <InputLabel className='select-label'>ステータス</InputLabel>
              <Select
                labelId='status-label'
                id='status-select'
                value={reportStatus}
                label='ステータス'
                onChange={(e) => setStatus(e.target.value)}
              >
                <MenuItem value=''><em>選択してください</em></MenuItem>
                <MenuItem value='未対応'>未対応</MenuItem>
                <MenuItem value='対応中'>対応中</MenuItem>
                <MenuItem value='対応済'>対応済</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12} style={{display: 'flex', justifyContent: 'center'}}>
            <div className='modal-buttons'>
              <Button variant='outlined' color='primary' startIcon={<ClearIcon />} onClick={handleClear}>クリア</Button>
              <Button variant='contained' color='primary' startIcon={<SearchIcon />} onClick={handleSearch}>検索</Button>
            </div>
          </Grid>
        
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};

export default ReportSearchForm;
