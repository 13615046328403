import React, { useState, useEffect, useCallback } from 'react';
import {
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
  Paper, TablePagination, TableSortLabel, Button, CircularProgress
} from '@mui/material';
import { Link } from 'react-router-dom';

interface Estimate {
  id: string;
  created_at: string;
  created_by: string;
  amount: string;
  report_id: string;
  report: {
    infra_id: string;
    infra: {
      name: string;
    }
  };
}

interface ListProps {
  filterEstimateParams: {
    estimateCreatedFrom: string;
    estimateCreatedTo: string;
    estimateInfraName: string;
    estimateAmountFrom: string;
    estimateAmountTo: string;
    estimateCreatedBy: string;
  } | null;
}

interface Header {
  label: string;
  value: keyof Estimate | 'actions' | 'report.infra_name';
}

const EstimateList: React.FC<ListProps> = ({ filterEstimateParams }) => {
  const [data, setData] = useState<Estimate[]>([]);
  const [filteredData, setFilteredData] = useState<Estimate[]>([]);
  const [rowsPerPage, setRowsPerPage] = useState<number>(5);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [orderBy, setOrderBy] = useState<string>('');
  const [order, setOrder] = useState<'asc' | 'desc'>('asc');
  const [loading, setLoading] = useState<boolean>(false);

  const fetchEstimateData = useCallback(async () => {
    setLoading(true);
    const token = localStorage.getItem('authToken');
    const headers = {
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json'
    };
  
    try {
      const response = await fetch('https://twondstar-backend.onrender.com/read/estimate', {
        method: 'GET',
        headers: headers
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const jsonData = await response.json();
      const filteredData = jsonData.filter((estimate: Estimate) => {
        const estimateDate = new Date(estimate.created_at);
        const fromDate = filterEstimateParams?.estimateCreatedFrom ? new Date(filterEstimateParams.estimateCreatedFrom) : null;
        const toDate = filterEstimateParams?.estimateCreatedTo ? new Date(filterEstimateParams.estimateCreatedTo) : null;
        const isDateInRange = (!fromDate || estimateDate >= fromDate) && (!toDate || estimateDate <= toDate);
        const amountFrom = filterEstimateParams?.estimateAmountFrom ? parseFloat(filterEstimateParams.estimateAmountFrom) : null;
        const amountTo = filterEstimateParams?.estimateAmountTo ? parseFloat(filterEstimateParams.estimateAmountTo) : null;
        const isAmountInRange = (!amountFrom || parseFloat(estimate.amount) >= amountFrom) && (!amountTo || parseFloat(estimate.amount) <= amountTo);
        const infraMatch = filterEstimateParams?.estimateInfraName ? estimate.report.infra.name.includes(filterEstimateParams.estimateInfraName) : true;
        const createdByMatch = filterEstimateParams?.estimateCreatedBy ? estimate.created_by.includes(filterEstimateParams.estimateCreatedBy) : true;
        return infraMatch && createdByMatch && isDateInRange && isAmountInRange;
      });
      setData(filteredData);
    } catch (error) {
      console.error('Error fetching infrastructure data:', error);
    }
    setLoading(false);
  }, [filterEstimateParams]);

  useEffect(() => {
    fetchEstimateData();
  }, [fetchEstimateData]);

  useEffect(() => {
    const result = data.filter(estimate => {
      const fromDateMatch = filterEstimateParams?.estimateCreatedFrom ? new Date(estimate.created_at) >= new Date(filterEstimateParams.estimateCreatedFrom) : true;
      const toDateMatch = filterEstimateParams?.estimateCreatedTo ? new Date(estimate.created_at) <= new Date(filterEstimateParams.estimateCreatedTo) : true;
      const amountFromMatch = filterEstimateParams?.estimateAmountFrom ? parseFloat(estimate.amount) >= parseFloat(filterEstimateParams.estimateAmountFrom) : true;
      const amountToMatch = filterEstimateParams?.estimateAmountTo ? parseFloat(estimate.amount) <= parseFloat(filterEstimateParams.estimateAmountTo) : true;
      return fromDateMatch && toDateMatch && amountFromMatch && amountToMatch;
    });
    setFilteredData(result);
  }, [data, filterEstimateParams]);

  // ページングコントロールのページ変更イベント
  const handleChangePage = (event: unknown, newPage: number) => {
    setCurrentPage(newPage);
  };

  // 表示行数変更イベント
  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setCurrentPage(0);
  };

  // ソート処理
  const handleSort = (property: string) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  // データフィルタリングとソート処理
  const sortedRows = orderBy ? [...filteredData].sort((a, b) => {
    const aValue = a[orderBy as keyof Estimate];
    const bValue = b[orderBy as keyof Estimate];

    if (aValue === undefined || bValue === undefined) return 0; // どちらかが未定義の場合は等しいとみなす

    if (typeof aValue === 'number' && typeof bValue === 'number') {
      // 数値の比較
      return order === 'asc' ? aValue - bValue : bValue - aValue;
    } else {
      // 文字列として比較
      return order === 'asc' ? aValue.toString().localeCompare(bValue.toString()) : bValue.toString().localeCompare(aValue.toString());
    }
  }) : [...filteredData];

  // 現在のページデータを計算
  const indexOfLastRow = (currentPage + 1) * rowsPerPage;
  const indexOfFirstRow = indexOfLastRow - rowsPerPage;
  const currentRows = sortedRows.slice(indexOfFirstRow, indexOfLastRow);

// ヘッダーの定義
const headers: Header[] = [
  { label: '見積No.', value: 'id' },
  { label: '作成日', value: 'created_at' },
  { label: '見積金額', value: 'amount' },
  { label: '通報No.', value: 'report_id' },
  { label: '対象インフラ', value: 'report.infra_name' },
  { label: '作成者', value: 'created_by' },
  { label: '', value: 'actions' }
];

  return (
    <div className='estimatelist'>
      <TableContainer component={Paper} style={{ overflow: 'auto' }}>
        <Table>
          <TableHead>
            <TableRow>
              {headers.map(header => (
                <TableCell key={header.value}>
                  <TableSortLabel
                    active={orderBy === header.value}
                    direction={orderBy === header.value ? order : 'asc'}
                    onClick={() => handleSort(header.value)}
                  >
                    {header.label}
                  </TableSortLabel>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              <TableRow>
                <TableCell colSpan={headers.length} style={{ textAlign: 'center' }}>
                  <CircularProgress />
                </TableCell>
              </TableRow>
            ) : (
              filteredData.length > 0 ? (
                filteredData.map((row: Estimate) => (
                  <TableRow key={row.id}>
                    <TableCell>{row.id}</TableCell>
                    <TableCell>{new Date(row.created_at).toLocaleDateString()}</TableCell>
                    <TableCell>{row.amount}</TableCell>
                    <TableCell>{row.report_id}</TableCell>
                    <TableCell>{row.report.infra ? row.report.infra.name : '対象なし' }</TableCell>
                    <TableCell>{row.created_by}</TableCell>
                    <TableCell>
                      <Link to={`/detail/estimate/${row.id}`}><Button variant='contained' size='small' color='primary'>詳細</Button></Link>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={headers.length} style={{ textAlign: 'center' }}>
                    データが見つかりません。
                  </TableCell>
                </TableRow>
              )
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component='div'
        count={sortedRows.length}
        rowsPerPage={rowsPerPage}
        page={currentPage}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        labelRowsPerPage='表示件数'
      />
    </div>
  );
};

export default EstimateList;
