import React from 'react';
import './Header.css';

const Header: React.FC = () => {

return(
  <div className='header'>

  </div>
)};

export default Header;