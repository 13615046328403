import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Button, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Paper } from '@mui/material';

import Header from '../header/Header';
import './Detail.css';

interface EstimateData {
  id: string;
  created_by: string;
  materials: string;
  labor: string;
  other: string;
  amount: string;
  created_at: string;
  report: {
    created_at: string;
    infra: {
      name: string;
    };
  };
  remarks: string;
}

interface Report {
  id: string;
  created_at: string;
  infra: {
    name: string;
  };
}

interface ModalProps {
  onClose: () => void;
  children?: React.ReactNode;
}


const Modal: React.FC<ModalProps> = ({ children }) => {
  return (
    <div className='modal-backdrop'>
      <div className='modal-content'>
        {children}
      </div>
    </div>
  );
};

const DetailEstimate: React.FC = () => {
  const { id } = useParams<{ id: string }>(); // URLからIDを取得
  const [estimateData, setEstimateData] = useState<EstimateData | null>(null); // デー���を保持するstate
  const [infraName, setInfraName] = useState<string | null>(null);
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    const token = localStorage.getItem('authToken');
    const headers = {
      'Authorization': `Bearer ${token}`, // 認証トークンをリクエストヘッダーに設定
      'Content-Type': 'application/json'
    };

    const fetchData = async () => {
      try {
        const response = await fetch(`https://twondstar-backend.onrender.com/read/estimate/${id}`, {
          method: 'GET',
          headers: headers
        });
        if (!response.ok) throw new Error(`Failed to fetch report data, status: ${response.status}`);
        const data = await response.json();
        setEstimateData(data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
  
    fetchData();
  }, [id]);  
  
  const toggleModal = () => setIsModalOpen(!isModalOpen);

  const goBack = () => {
    navigate(-1);
  };

  return (
    <div className='details'>
      <Header />
      <div className='subheader'>
        <h4>見積詳細</h4>
      </div>
      <div className='detail'>
        <div className='progress'>

          <div className='step'>
            <p>損傷報告</p>
            <p>{estimateData?.report?.created_at ? new Date(estimateData.report.created_at).toLocaleDateString() : '日付なし'}</p>
            <div className='blank'></div>
          </div>

          <div className='line'></div>

          <div className='step'>
            <p>対策見積</p>
            <p>{estimateData?.created_at ? new Date(estimateData.created_at).toLocaleDateString() : '日付なし'}</p>
            <div className='blank'></div>
          </div>

          <div className='line'></div>

          <div className='step'>
            <p>対策案決定</p>
            <p>-/-/-</p>
            <div className='blank'> </div>
          </div>

        </div>

        {/* 詳細ページの表示 */}
        {estimateData && (
          <div className='contents'>
            <div className='content'>
              <p className='title'>見積No.</p>
              <p className='No'>{estimateData.id}</p>
              <div className='under-line'></div>
            </div>
            <div className='content'>
              <p className='title'>作成者</p>
              <p>{estimateData.created_by}</p>
              <div className='under-line'></div>
            </div>
          </div>
        )}
         <div className='plan-table'>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow sx={{ backgroundColor: '#E8E8E7' }}>
                  <TableCell>項目</TableCell>
                  <TableCell>金額</TableCell>
                  <TableCell>数量</TableCell>
                  <TableCell>合計金額(税込)</TableCell>
                </TableRow>
              </TableHead>
              
            </Table>
          </TableContainer>
        </div>
        <div className='remarks'>
          <p className='title'>備考</p>
          <p>{estimateData?.remarks}</p>
        </div>

        <div className='buttons'>
          <Button variant='outlined' size='small' color='primary' onClick={goBack}>戻る</Button>
          <Button variant='contained' size='small' color='primary' >編集</Button>
        </div>
      </div>
    </div>
  );
};

export default DetailEstimate;
