import React, { useState, useEffect } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { Button } from '@mui/material';

import Header from '../header/Header';
import './Detail.css';

interface ReportData {
  id: string;
  create_at: string;
  answer: string;
  status: string;
  latitude: string;
  longitude: string;
  zoom_out: string,
  zoom_in: string,
  remarks: string,
  name: string,
  contact: string,
  city: string,
  infra_id: string,
  category: string,
  address: string,
}

interface Infra {
  id: string;
  name: string;
}

interface ModalProps {
  onClose: () => void;
  children?: React.ReactNode;
}


const Modal: React.FC<ModalProps> = ({ children }) => {
  return (
    <div className='modal-backdrop'>
      <div className='modal-content'>
        {children}
      </div>
    </div>
  );
};

const DetailReport: React.FC = () => {
  const { id } = useParams<{ id: string }>(); // URLからIDを取得
  const [reportData, setReportData] = useState< ReportData | null>(null); // データを保持するstate
  const [infraName, setInfraName] = useState<string | null>(null);
  const [infraList, setInfraList] = useState<Infra[]>([]);
  const [selectedInfraId, setSelectedInfraId] = useState<string>('placeholder');
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState(''); // 検索キーワード用の状態変数
  const [filteredInfraList, setFilteredInfraList] = useState<Infra[]>([]); // フィルタリングされたインフラリスト

  useEffect(() => {
    const token = localStorage.getItem('authToken');
    const headers = {
      'Authorization': `Bearer ${token}`, // 認証トークンをリクエストヘッダーに設定
      'Content-Type': 'application/json'
    };

    const fetchData = async () => {
      try {
        const response = await fetch(`https://twondstar-backend.onrender.com/read/report/${id}`, {
          method: 'GET',
          headers: headers
        });
        if (!response.ok) throw new Error(`Failed to fetch report data, status: ${response.status}`);
        const data = await response.json();
        
        const addressResponse = await fetch(`https://nominatim.openstreetmap.org/reverse?format=json&lat=${data.latitude}&lon=${data.longitude}&zoom=18`);
        if (addressResponse.ok) {
          const addressData = await addressResponse.json();
          console.log(addressData);
          const {address} = addressData;
  
          // 日本形式の住所を構成
          const formattedAddress = [
            address.state,
            address.city || address.town || address.village, // 市区町村レベルで最も適切なものを選択
            address.road || '', // 道路名、存在する場合
            address.house_number || '' // 番地、存在する場合
          ].filter(part => part).join(""); // 未定義または空の部分を除外して結合
  
          data.address = formattedAddress;
        }
  
        setReportData(data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
  
    fetchData();
  }, [id]);  
  
  useEffect(() => {
    const fetchInfraName = async () => {
      if (!reportData?.infra_id) {
        setInfraName('未設定');
        return;
      }
      const token = localStorage.getItem('authToken'); // トークンをローカルストレージから取得
      if (!token) {
        console.error('Authentication token is not available');
        setInfraName('未設定');
        return;
      }
      const headers = {
        'Authorization': `Bearer ${token}`, // トークンをヘッダーに設定
        'Content-Type': 'application/json'
      };
      try {
        const response = await fetch(`https://twondstar-backend.onrender.com/read/infra/${reportData.infra_id}`, {
          method: 'GET',
          headers: headers
        });
        if (!response.ok) {
          throw new Error(`Failed to fetch infra data, status: ${response.status}`);
        }
        const infraData = await response.json();
        if (infraData && infraData.name) {
          setInfraName(infraData.name);
        } else {
          setInfraName('未設定');
        }
      } catch (error) {
        console.error('Error fetching infra data:', error);
        setInfraName('未設定');
      }
    };
  
    fetchInfraName();
  }, [reportData?.infra_id]);
  
  

  useEffect(() => {
    const token = localStorage.getItem('authToken');
    const headers = {
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json'
    };
    // インフラデータの取得
    const fetchInfraData = async () => {
      const response = await fetch(`https://twondstar-backend.onrender.com/read/infra`, {
        method: 'GET',
        headers: headers
      });
      const data: Infra[] = await response.json();
      setInfraList(data);
      setFilteredInfraList(data); // 初期リストを設定
    };

    fetchInfraData();
  }, []);

  useEffect(() => {
    // 検索キーワードに基づいてインフラリストをフィルタリング
    const filtered = infraList.filter(infra =>
      infra.name.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredInfraList(filtered);
  }, [searchTerm, infraList]); // 検索キーワードまたはインフラリストが変更されたときにフィルタリングを実行

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value); // ユーザーの入力を検索キーワードとして設定
  };

  const handleInfraChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedInfraId(event.target.value);
  };
  
  const toggleModal = () => setIsModalOpen(!isModalOpen);

  const goBack = () => {
    navigate(-1);
  };

  const formatUpdateDate = (dateString: string) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // 月は0-indexedなので+1する
    const day = date.getDate().toString().padStart(2, '0'); // 日付を取得
    return `${year}/${month}/${day}`; // 年月日の形式に変換
  };

  if (!reportData) {
    return <div>Loading...</div>; // ローディング状態の表示
  }

  const updateInfraId = async () => {
    try {
      const response = await fetch(`https://twondstar-backend.onrender.com/report/${id}/infra-id`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ infra_id: selectedInfraId }),
      });
  
      if (!response.ok) {
        throw new Error('Failed to update infra_id');
      }
  
      // reportDataのinfra_idを更新
      setReportData(prev => {
        if (prev === null) {
          // ここではprevがnullの場合の適切な処理を行う必要があります
          // 例: 新しい空のReportDataオブジェクトを返す、nullを返して処理を終了する、等
          return null; 
        }
  
        // prevがnullでない場合、新しいinfra_idでオブジェクトを更新
        return { ...prev, infra_id: selectedInfraId };
      });
  
      // 必要であれば、新たなインフラの名前も状態に反映
      const newInfra = infraList.find(infra => infra.id === selectedInfraId);
      setInfraName(newInfra ? newInfra.name : '未設定');
  
      alert('インフラIDが更新されました');
      toggleModal(); // モーダルを閉じる
    } catch (error) {
      console.error('Error updating infra_id:', error);
    }
  };

  return (
    <div className='details'>
      <Header />
      <div className='subheader'>
        <h4>報告詳細</h4>
      </div>
      <div className='detail'>
        <div className='progress'>

          <div className='step'>
            <p>損傷報告</p>
            <p>{formatUpdateDate(reportData.create_at)}</p>
            <div className='blank'></div>
          </div>

          <div className='line'></div>

          <div className='step'>
            <p>対策見積</p>
            <p>-/-/-</p>
            <Link to={`/plan/${id}`}><Button variant='contained' size='small' color='primary'>作成</Button></Link>
          </div>

          <div className='line'></div>

          <div className='step'>
            <p>対策案決定</p>
            <p>-/-/-</p>
            <div className='blank'> </div>
          </div>

        </div>

        {/* 詳細ページの表示 */}
        {reportData && (
          <div className='contents'>
            <div className='content'>
              <p className='title'>報告No.</p>
              <p className='No'>{reportData.id}</p>
              <div className='under-line'></div>
              <p className='title'>自動判定</p>
              <p>{reportData.answer}</p>
              <div className='under-line'></div>
              <p className='title'>住所</p>
              <p>{reportData.address}</p>
              <div className='under-line'></div>
              <p className='title'>通報者氏名</p>
              <p className='No'>{reportData.name}</p>
              <div className='under-line'></div>
              <p className='title'>インフラ写真</p>
              <img src={reportData.zoom_out} className='imgs' alt='zoom_out'></img>
            </div>
            <div className='content'>
              <p className='title'>カテゴリー</p>
              <p>{reportData.category}</p>
              <div className='under-line'></div>
              <p className='title'>対応状況</p>
              <p>{reportData.status}</p>
              <div className='under-line'></div>
              <p className='title'>インフラ名</p>
              {infraName === '未設定' ? (
                <p className='link-like' onClick={() => infraName === '未設定' && toggleModal()}>{infraName}</p>
              ) : (
                <Link to={`/detail/infra/${reportData?.infra_id}`}><p>{infraName}</p></Link>
              )}
              <div className='under-line'></div>
              <p className='title'>通報者連絡先</p>
              <p className='No'>{reportData.contact}</p>
              <div className='under-line'></div>
              <p className='title'>損傷写真</p>
              <img src={reportData.zoom_in} className='imgs' alt='zoom_in'></img>
            </div>
          </div>
        )}
        <div className='remarks'>
          <p className='title'>備考</p>
          <p>{reportData.remarks}</p>
        </div>

        {isModalOpen && (
        <Modal onClose={toggleModal}>
          <h4>紐付けるインフラを選択</h4>

          <input type='text' placeholder='インフラを検索' value={searchTerm} onChange={handleSearchChange} className='search-box' />
          
          <select value={selectedInfraId} onChange={handleInfraChange} className='infra_id'>
              <option value="placeholder" disabled>インフラを選択してください</option>
              {filteredInfraList.map(infra => (
                <option key={infra.id} value={infra.id}>{infra.name}</option>
              ))}
            </select>
          <div className='buttons'>
            <Button variant='outlined' size='small' color='primary' onClick={toggleModal}>閉じる</Button>
            <Button variant='contained' size='small' color='primary' onClick={updateInfraId}>更新</Button>
          </div>
        </Modal>
        )}

        <div className='buttons'>
          <Button variant='outlined' size='small' color='primary' onClick={goBack}>戻る</Button>
          <Button variant='contained' size='small' color='primary' onClick={toggleModal}>インフラ紐付け</Button>
        </div>
      </div>
    </div>
  );
};

export default DetailReport;