import React, { useState, useEffect } from 'react';
import {
  Table, TableBody, TableCell, TableContainer,
  TableHead, TableRow, Paper, TablePagination, TableSortLabel, Button, CircularProgress
} from '@mui/material';
import { Link } from 'react-router-dom';

interface Report {
  id: string;
  latitude: number;
  longitude: number;
  address?: string;
  category: string;
  create_at: string;
  answer: string;
  status: string;
}

interface AddressCache {
  [key: string]: string;
}

interface ListProps {
  filterReportParams: {
    reportDateFrom: string;
    reportDateTo: string;
    reportPlace: string;
    reportCategory: string;
    reportAnswer: string;
    reportStatus: string;
  } | null;
}

interface Header {
  label: string;
  value: keyof Report | 'actions';
}

const ReportList: React.FC<ListProps> = ({ filterReportParams }) => {
  const [data, setData] = useState<Report[]>([]);
  const [addressCache, setAddressCache] = useState<AddressCache>({});
  const [rowsPerPage, setRowsPerPage] = useState<number>(5);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [orderBy, setOrderBy] = useState<keyof Report | null>(null);
  const [order, setOrder] = useState<'asc' | 'desc'>('asc');
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const token = localStorage.getItem('authToken');
      if (!token) {
        console.error('Authentication token is not available.');
        alert('Please log in to access the data.');
        setLoading(false);
        return;
      }
      const headers = {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      };

      try {
        const response = await fetch('https://twondstar-backend.onrender.com/read/report', {
          method: 'GET',
          headers: headers
        });

        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const jsonData = await response.json();

        const promises = jsonData.map(async (report: { address: any; latitude: any; longitude: any; }) => {
          if (!report.address && report.latitude && report.longitude) {
            report.address = await fetchAddress(report.latitude, report.longitude);
          }
          return report;
        });
    
        const reportsWithAddress = await Promise.all(promises);

        let filteredData = jsonData;

        if (filterReportParams) {
          filteredData = jsonData.filter((report: Report) => {
            const reportDate = new Date(report.create_at);
            const fromDate = filterReportParams.reportDateFrom ? new Date(filterReportParams.reportDateFrom) : null;
            const toDate = filterReportParams.reportDateTo ? new Date(filterReportParams.reportDateTo) : null;
            const isDateInRange = (!fromDate || reportDate >= fromDate) && (!toDate || reportDate <= toDate);
            const isPlaceMatch = filterReportParams?.reportPlace ? 
              report.address?.toLowerCase().includes(filterReportParams.reportPlace.toLowerCase()) : true;
            const isCategoryMatch = !filterReportParams.reportCategory || report.category.toLowerCase() === filterReportParams.reportCategory.toLowerCase();
            const isAnswerMatch = !filterReportParams.reportAnswer || report.answer.toLowerCase() === filterReportParams.reportAnswer.toLowerCase();
            const isStatusMatch = !filterReportParams.reportStatus || report.status.toLowerCase() === filterReportParams.reportStatus.toLowerCase();

            return isDateInRange && isPlaceMatch && isCategoryMatch && isAnswerMatch && isStatusMatch;
          });
        }

        setData(filteredData);
      } catch (error) {
        console.error('Failed to fetch data:', error);
      }
      setLoading(false);
    };

    fetchData();
  }, [filterReportParams]);

  const fetchAddress = async (latitude: number, longitude: number) => {
    const key = `${latitude},${longitude}`;
    if (addressCache[key]) {
      return addressCache[key]; // 既にキャッシュされている場合はそれを返す
    }

    const url = `https://nominatim.openstreetmap.org/reverse?format=json&lat=${latitude}&lon=${longitude}&zoom=18&addressdetails=1`;
    const response = await fetch(url);
    if (!response.ok) {
      console.error('Error fetching address');
      return "住所情報の取得に失敗しました";
    }
    const addressData = await response.json();
    const address = addressData.address;

    // 都道府県、市町村、地区、道路、番地などを取得し、undefinedの場合は空文字列に置き換える
    const province = address.province || '';
    const state = address.state || '';
    const region  = address.region || '';
    const country = address.county || '';
    const city = address.city || '';
    const district = address.district || '';
    const suburb = address.suburb || '';
    const town = address.town || '';
    const village = address.village || '';
    const neighbourhood = address.neighbourhood || '';
    const road = address.road || '';
    const house_number = address.house_number || '';
    const house_name = address.house_name || '';

    // 住所を組み立てる
    const formattedAddress = [ province, state, region, country, city, district, suburb, town, village, neighbourhood, road, house_number, house_name ].filter(part => part).join('');

    addressCache[key] = formattedAddress;
    return formattedAddress;
  };

  useEffect(() => {
    data.forEach(async (report) => {
      if (!report.address && report.latitude && report.longitude) {
        const address = await fetchAddress(report.latitude, report.longitude);
        setData(currentData =>
          currentData.map(r => r.id === report.id ? {...r, address} : r)
        );
      }
    });
  }, [data]);

  const handleSort = (property: keyof Report | 'actions') => {
    if (property === 'actions') {
      // actionsカラムの場合は何もしない
      return;
    }
    
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setCurrentPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setCurrentPage(0);
  };

  const sortedRows = orderBy ? data.sort((a, b) => {
    const aValue = a[orderBy];
    const bValue = b[orderBy];
  
    if (aValue === undefined || bValue === undefined) return 0;
  
    if (typeof aValue === 'number' && typeof bValue === 'number') {
      return order === 'asc' ? aValue - bValue : bValue - aValue;
    }
  
    return order === 'asc' ? aValue.toString().localeCompare(bValue.toString()) : bValue.toString().localeCompare(aValue.toString());
  }) : [...data];  

  const indexOfLastRow = (currentPage + 1) * rowsPerPage;
  const indexOfFirstRow = indexOfLastRow - rowsPerPage;
  const currentRows = sortedRows.slice(indexOfFirstRow, indexOfLastRow);

  // ヘッダーの定義
  const headers: Header[] = [
    { label: '報告No.', value: 'id' },
    { label: '報告日', value: 'create_at' },
    { label: '報告場所', value: 'address' },
    { label: 'カテゴリー', value: 'category' },
    { label: '自動判定', value: 'answer' },
    { label: 'ステータス', value: 'status' },
    { label: '', value: 'actions' }
  ];

  return (
    <div className='reportlist'>
      <TableContainer component={Paper} style={{ overflow: 'auto' }}>
        <Table>
          <TableHead>
            <TableRow>
              {headers.map(header => (
                <TableCell key={header.value}>
                  <TableSortLabel
                    active={orderBy === header.value}
                    direction={orderBy === header.value ? order : 'asc'}
                    onClick={() => handleSort(header.value)}
                  >
                    {header.label}
                  </TableSortLabel>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              <TableRow>
                <TableCell colSpan={headers.length} style={{ textAlign: 'center' }}>
                  <CircularProgress />
                </TableCell>
              </TableRow>
            ) : (
              currentRows.map((row: Report) => (
                <TableRow key={row.id}>
                  <TableCell>{row.id}</TableCell>
                  <TableCell>{new Date(row.create_at).toLocaleDateString()}</TableCell>
                  <TableCell>{row.address}</TableCell>
                  <TableCell>{row.category}</TableCell>
                  <TableCell>{row.answer}</TableCell>
                  <TableCell>{row.status}</TableCell>
                  <TableCell>
                    <Link to={`/detail/report/${row.id}`}>
                      <Button variant='contained' size='small' color='primary'>詳細</Button>
                    </Link>
                  </TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component='div'
        count={sortedRows.length}
        rowsPerPage={rowsPerPage}
        page={currentPage}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        labelRowsPerPage='表示件数'
      />
    </div>
  );
};

export default ReportList;