import React, { createContext, useContext, useState, useEffect, ReactNode } from 'react';

// ユーザー型定義
type UserType = {
  email: string;
  project_id?: number; // プロジェクトIDはオプショナル
};

// ユーザーコンテキストの型定義
type UserContextType = {
  user: UserType | null; // ユーザー情報
  setUser: React.Dispatch<React.SetStateAction<UserType | null>>; // ユーザー情報を更新する関数
  logout: () => void; // ログアウト関数
  login: (email: string, password: string) => void; // ログイン関数
  };

// ユーザーコンテキストの作成
const UserContext = createContext<UserContextType | undefined>(undefined);

// ユーザープロバイダーコンポーネント
export const UserProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [user, setUser] = useState<UserType | null>(null); // ユーザー状態

  // コンポーネントのマウント時にローカルストレージからユーザー情報を読み込む
  useEffect(() => {
    const storedUserInfo = localStorage.getItem('userInfo');
    if (storedUserInfo) {
      setUser(JSON.parse(storedUserInfo));
    }
  }, []);

  // ログイン関数
  const login = async (email: string, password: string) => {
    // ログイン処理（省略）
  };

  // ログアウト関数
  const logout = () => {
    localStorage.removeItem('userInfo'); // ユーザー情報の削除
    localStorage.removeItem('authToken'); // 認証トークンの削除
    setUser(null); // ユーザー状態をnullに設定
  };

  // ユーザーコンテキストを提供
  return (
    <UserContext.Provider value={{ user, setUser, logout, login }}>
      {children}
    </UserContext.Provider>
  );
};

// ユーザーコンテキストフック
export const useUser = () => {
  const context = useContext(UserContext);
  if (context === undefined) {
    throw new Error('useUser must be used within a UserProvider');
  }
  return context;
};