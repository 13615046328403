import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Button } from '@mui/material';
import { useUser } from '../UserContext';

import  './Signup.css'

import logo from '../icon/silhouette_white.png';

const Signup = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const { setUser } = useUser();
  const navigate = useNavigate();

const handleSignup = async (e: { preventDefault: () => void; }) => {
  e.preventDefault();
  setLoading(true);
  const response = await fetch('https://twondstar-backend.onrender.com/signup', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ email, password }),
  });

  const data = await response.json();

  if (response.ok) {
    setUser({ email: email });
    navigate('/dashboard');
  } else {
    alert(`Error: ${data.message}`);
  }
  setLoading(false);
};

  return (
    <div className='signup'>
      <div className='signup-header'>
        <img src={logo} alt='logo' className='logo-header'/>
      </div>
      <form onSubmit={handleSignup}>
        <div className='link'>
          <Link to='/login'><p className='link'>ログイン</p></Link>
        </div>
        <div className='input-field'>
        <p>メールアドレス</p>
        <input type='email' placeholder='Email' value={email} onChange={(e) => setEmail(e.target.value)} className='signup-input'/>
        </div>
        <div className='input-field'>
        <p>パスワード</p>
        <input type='password' placeholder='Password' value={password} onChange={(e) => setPassword(e.target.value)} className='signup-input'/>
        </div>
        <div className='submit-margin'></div>
        <Button type='submit' disabled={loading} variant='contained' size='small' color='primary'>アカウント作成</Button>
      </form>
      <div className='footer'>

      </div>
    </div>
  );
};

export default Signup;
