import React from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import { UserProvider } from './components/UserContext';
import ProtectedRoute from './components/ProtectedRoute';
import Sidebar from './components/Sidebar';
import MainContent from './components/MainContent';

import './App.css';

// AppContentコンポーネントを定義して、useLocationフックを使えるようにします
const AppContent: React.FC = () => {
  const location = useLocation(); // 現在のロケーションを取得

  // /signupまたは/loginのパスの時だけSidebarを非表示にするフラグ
  const hideSidebar = location.pathname === '/login' || location.pathname === '/signup' || location.pathname === '/';

  return (
    <div className='body'>
      {!hideSidebar && (
        <div className='sidebar'>
          <Sidebar />
        </div>
      )}
    <Routes>
        {/* ログイン・サインアップページだけは直接アクセスを許可 */}
        <Route path='/login' element={<MainContent selectedComponent='login' />} />
        <Route path='/signup' element={<MainContent selectedComponent='signup' />} />
        
        {/* 他の全てのページにはアクセス制限をかける */}
        <Route path='/' element={
          <ProtectedRoute>
            <MainContent />
          </ProtectedRoute>
        } />
        <Route path='/dashboard' element={
          <ProtectedRoute>
            <MainContent selectedComponent='dashboard' />
          </ProtectedRoute>
        } />
        <Route path='/list' element={
          <ProtectedRoute>
            <MainContent selectedComponent='list' />
          </ProtectedRoute>
        } />
        <Route path='/map' element={
          <ProtectedRoute>
            <MainContent selectedComponent='map' />
          </ProtectedRoute>
        } />
        <Route path='/schedule' element={
          <ProtectedRoute>
            <MainContent selectedComponent='schedule' />
          </ProtectedRoute>
        } />
        <Route path='/detail/infra/:id' element={
          <ProtectedRoute>
            <MainContent selectedComponent='detail/infra' />
          </ProtectedRoute>
        } />
        <Route path='/detail/report/:id' element={
          <ProtectedRoute>
            <MainContent selectedComponent='detail/report' />
          </ProtectedRoute>
        } />
        <Route path='/detail/estimate/:id' element={
          <ProtectedRoute>
            <MainContent selectedComponent='detail/estimate' />
          </ProtectedRoute>
        } />
        <Route path='/plan/:id' element={
          <ProtectedRoute>
            <MainContent selectedComponent='plan' />
          </ProtectedRoute>
        } />
      </Routes>
    </div>
  );
};

const App: React.FC = () => {
  return (
    <Router>
      <UserProvider>
        <AppContent />
      </UserProvider>
    </Router>
  );
}

export default App;