import React, { useState, useEffect } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import Select from 'react-select';
import { Options, SingleValue, MultiValue } from 'react-select';

import Header from '../header/Header';
import './Plan.css';

interface Type {
  id: number;
  construction_type: string;
  work_type: string;
}

interface Detail {
  id: number;
  description: string;
  amount: number;
  type_id: number;
  work_type: string;
}

interface Department {
  id: number;
  name: string;
  contact: string;
  value: string;
  label: string;
}

const Plan: React.FC = () => {
  const [types, setTypes] = useState<Type[]>([]);
  const [selectedConstructionType, setSelectedConstructionType] = useState<string>('');
  const [filteredWorkTypes, setFilteredWorkTypes] = useState<string[]>([]);
  const [details, setDetails] = useState<Detail[]>([]);
  const [selectedDetail, setSelectedDetail] = useState<string>('');
  const [filteredDetails, setFilteredDetails] = useState<Detail[]>([]);
  const [quantities, setQuantities] = useState<{ [key: number]: number }>({});
  const [lineTotals, setLineTotals] = useState<number[]>([]);
  const [totalCost, setTotalCost] = useState<number>(0);
  const [departmentOptions, setDepartmentOptions] = useState<Department[]>([]);
  const [selectedDepartments, setSelectedDepartments] = useState<string[]>([]);
  const navigate = useNavigate();

  const token = localStorage.getItem('authToken');
  const headers = {
    'Authorization': `Bearer ${token}`,
    'Content-Type': 'application/json'
  };

  const uniqueCategories = Array.from(new Set(types.map(type => type.construction_type)));

  useEffect(() => {
    fetch('https://twondstar-backend.onrender.com/read/type/', { headers })
      .then(response => response.json())
      .then(data => setTypes(data))
      .catch(error => console.error('Error fetching types:', error));

    fetch('https://twondstar-backend.onrender.com/read/type-detail/', { headers })
      .then(response => response.json())
      .then(data => setDetails(data))
      .catch(error => console.error('Error fetching details:', error));

    fetch('https://twondstar-backend.onrender.com/read/department/', { headers })
      .then(response => response.json())
      .then(data => {
        const departmentOptions = data.map((department: any) => ({
          value: department.id,
          label: department.name,
        }));
        setDepartmentOptions(departmentOptions);
      })
      .catch(error => console.error('Error fetching departments:', error));
  }, []);

  useEffect(() => {
    if (selectedConstructionType) {
      const workTypesFiltered = types.filter(type => type.construction_type === selectedConstructionType).map(type => type.work_type);
      setFilteredWorkTypes(workTypesFiltered);
    } else {
      setFilteredWorkTypes([]);
    }
  }, [selectedConstructionType, types]);

  useEffect(() => {
    const typeIds = types.filter(type => type.work_type === selectedDetail).map(type => type.id);
    const filtered = details.filter(detail => typeIds.includes(detail.type_id));
    setFilteredDetails(filtered);
  }, [selectedDetail, types, details]);

  useEffect(() => {
    const newLineTotals = filteredWorkTypes.map(workType => {
      const workTypeObject = details.find(detail => detail.work_type === workType);
      if (!workTypeObject) {
        console.error('workTypeObject not found for workType:', workType);
        return 0;
      }
      const quantity = quantities[workTypeObject.type_id] || 1;
      return (workTypeObject.amount || 0) * quantity;
    });
    setLineTotals(newLineTotals);
    setTotalCost(newLineTotals.reduce((acc, curr) => acc + curr, 0));
  }, [filteredWorkTypes, quantities]);

  const handleTypeChange = (value: string) => {
    setSelectedConstructionType(value);
    setSelectedDetail('');
  };

  const handleDetailChange = (value: string) => {
    setSelectedDetail(value);
  };

  const handleQuantityChange = (detailId: number, event: React.ChangeEvent<HTMLInputElement>) => {
    const newQuantities = { ...quantities, [detailId]: parseInt(event.target.value) || 0 };
    setQuantities(newQuantities);
  };

  const goBack = () => {
    navigate(-1);
  };

  return (
    <div className='page'>
      <Header />
      <div className='subheader'>
        <h4>対策見積作成</h4>
      </div>
      
      <div className='plan'>
        <div className='detail'>
          <div className='contents'>
            <div className='content'>
              <p>見積情報</p>
              <div className='under-line'></div>
              <p className='title'>工事種別</p>
              <Select
                options={uniqueCategories.map(category => ({ value: category, label: category }))}
                onChange={(selectedOption: SingleValue<{ value: string; label: string; }>) =>
                  handleTypeChange(selectedOption ? selectedOption.value : '')
                }
                value={uniqueCategories.find(category => category === selectedConstructionType) ? { value: selectedConstructionType, label: selectedConstructionType } : null}
                className="basic-single-select"
                classNamePrefix="select"
              />
              <p className='title'>詳細種別</p>
              <Select
                options={filteredWorkTypes.map(workType => ({ value: workType, label: workType }))}
                onChange={(selectedOption: SingleValue<{ value: string; label: string; }>) =>
                  handleDetailChange(selectedOption ? selectedOption.value : '')
                }
                value={filteredWorkTypes.find(workType => workType === selectedDetail) ? { value: selectedDetail, label: selectedDetail } : null}
                className="basic-single-select"
                classNamePrefix="select"
              />
            </div>


            <div className='content'>
              <p>連携先</p>
              <div className='under-line'></div>
              <p className='title'>部署名</p>
              <Select
                options={departmentOptions}
                isMulti
                className="basic-multi-select"
                classNamePrefix="select"
                onChange={(selectedOptions: MultiValue<{ value: string; label: string; }>) =>
                  setSelectedDepartments(selectedOptions ? selectedOptions.map((option) => option.value) : [])
                }
                value={departmentOptions.filter(option => selectedDepartments.includes(option.value))}
              />
            </div>

          </div>

            <div className='plan-table'>
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow sx={{ backgroundColor: '#E8E8E7' }}>
                      <TableCell>項目</TableCell>
                      <TableCell>金額</TableCell>
                      <TableCell>数量</TableCell>
                      <TableCell>合計金額(税込)</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {filteredDetails.map((detail) => (
                      <TableRow key={detail.id}>
                        <TableCell>{detail.description}</TableCell>
                        <TableCell>{detail.amount}</TableCell>
                        <TableCell>
                          <input type="number" value={quantities[detail.id] || 0} onChange={(e) => handleQuantityChange(detail.id, e)} className='plan-make-input' />
                        </TableCell>
                        <TableCell>{(quantities[detail.id] || 0) * detail.amount}</TableCell>
                      </TableRow>
                    ))}
                    <TableRow>
                      <TableCell colSpan={3}>合計</TableCell>
                      <TableCell>
                        {Object.keys(quantities).reduce((acc, key) => {
                          const detail = details.find(d => d.id === parseInt(key));
                          return acc + (quantities[parseInt(key)] || 0) * (detail ? detail.amount : 0);
                        }, 0)}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
            <div className='buttons'>
              <Button variant='outlined' onClick={goBack}>戻る</Button>
              <Button variant='contained'>作成</Button>
            </div>
          </div>
      </div>
    </div>
  );
};

export default Plan;