import React, { useState } from 'react';
import ReportSearchForm from '../searchform/ReportSearchForm';
import Calender from '../calendar/Calendar';

import Header from '../header/Header';
import './Schedule.css';

const Schedule: React.FC = () => {
  const [filterReportParams, setFilterReportParams] = useState<{
    reportDateFrom: string;
    reportDateTo: string;
    reportPlace: string;
    reportCategory: string;
    reportAnswer: string;
    reportStatus: string;
  } | null>(null);

  const handleSearch = (params: typeof filterReportParams) => {
    setFilterReportParams(params);
  };

  const handleClear = () => {
    setFilterReportParams(null);
  };

  return (
    <div className='schedule'>
      <Header />
      <div className='subheader'>
        <h4>カレンダー</h4>
      </div>
      <div className='calendar'>
        <ReportSearchForm onSearch={handleSearch} onClear={handleClear}/>
        <div className='margin'></div>
        <Calender filterReportParams={filterReportParams} />
      </div>
    </div>
  );
};

export default Schedule;