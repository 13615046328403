import React, { useState } from 'react';
import { Accordion, AccordionSummary, AccordionDetails, TextField, FormControl, InputLabel, Select, MenuItem, Button, Grid } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';

import './SearchForm.css';

interface FilterParams {
  estimateCreatedFrom: string;
  estimateCreatedTo: string;
  estimateInfraName: string;
  estimateAmountFrom: string;
  estimateAmountTo: string;
  estimateCreatedBy: string;
};

interface ReportSearchFormProps {
  onSearch: (filterParams: FilterParams) => void;
  onClear: () => void;
};

const ReportSearchForm = ({ onSearch, onClear }: ReportSearchFormProps) => {
  const [estimateCreatedFrom, setEstimateCreatedFrom] = useState('');  
  const [estimateCreatedTo, setEstimateCreatedTo] = useState('');
  const [estimateInfraName, setEstimateInfraName] = useState('');
  const [estimateAmountFrom, setEstimateAmountFrom] = useState('');
  const [estimateAmountTo, setEstimateAmountTo] = useState('');
  const [estimateCreatedBy, setEstimateCreatedBy] = useState('');

  const handleSearch = () => {
    onSearch({
      estimateCreatedFrom: estimateCreatedFrom.trim(),
      estimateCreatedTo: estimateCreatedTo.trim(),
      estimateInfraName: estimateInfraName.trim(),
      estimateAmountFrom: estimateAmountFrom.trim(),
      estimateAmountTo: estimateAmountTo.trim(),
      estimateCreatedBy: estimateCreatedBy.trim(),
    });
  };

  const handleClear = () => {
    setEstimateCreatedFrom('');
    setEstimateCreatedTo('');
    setEstimateInfraName('');
    setEstimateAmountFrom('');
    setEstimateAmountTo('');
    setEstimateCreatedBy('');
    onClear();
  };

  return (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMoreIcon />} style={{ height: '60px', alignItems: 'center' }}>
        <h4>詳細検索</h4>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={4}>
            <FormControl fullWidth>
              <TextField
                label='作成日(から)'
                type='date'
                value={estimateCreatedFrom}
                onChange={(e) => setEstimateCreatedFrom(e.target.value)}
                InputLabelProps={{ shrink: true }}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <FormControl fullWidth>
              <TextField
                label='作成日(まで)'
                type='date'
                value={estimateCreatedTo}
                onChange={(e) => setEstimateCreatedTo(e.target.value)}
                InputLabelProps={{ shrink: true }}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <FormControl fullWidth>
              <TextField
                label='対象インフラ'
                type='text'
                value={estimateInfraName}
                onChange={(e) => setEstimateInfraName(e.target.value)}
                InputLabelProps={{ shrink: true }}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <FormControl fullWidth>
              <TextField
                label='見積金額(から)'
                type='text'
                value={estimateAmountFrom}
                onChange={(e) => setEstimateAmountFrom(e.target.value)}
                InputLabelProps={{ shrink: true }}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <FormControl fullWidth>
              <TextField
                label='見積金額(まで)'
                type='text'
                value={estimateAmountTo}
                onChange={(e) => setEstimateAmountTo(e.target.value)}
                InputLabelProps={{ shrink: true }}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <FormControl fullWidth>
              <TextField
                label='作成者'
                type='text'
                value={estimateCreatedBy}
                onChange={(e) => setEstimateCreatedBy(e.target.value)}
                InputLabelProps={{ shrink: true }}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} style={{display: 'flex', justifyContent: 'center'}}>
            <div className='modal-buttons'>
              <Button variant='outlined' color='primary' startIcon={<ClearIcon />} onClick={handleClear}>クリア</Button>
              <Button variant='contained' color='primary' startIcon={<SearchIcon />} onClick={handleSearch}>検索</Button>
            </div>
          </Grid>
        
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};

export default ReportSearchForm;
