import React from 'react';
import { Navigate } from 'react-router-dom';
import { useUser } from './UserContext'; // UserContext のパスを適宜修正

interface ProtectedRouteProps {
  children: JSX.Element;
}

const ProtectedRoute = ({ children }: ProtectedRouteProps) => {
  const { user } = useUser();

  if (!user) {
    // ユーザーが未ログインの場合、ログインページにリダイレクト
    return <Navigate to='/login' />;
  }

  return children;
};

export default ProtectedRoute;